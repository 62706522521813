<template>
  <!-- upload上传组件 -->
  <div>
    <!-- [按钮]上传图片&文件-[前端->后端] -->
    <mainimgesup
      v-model="ruleForm.sourcefile"
      :myimgesup="myimgesup"
      :myimgesupisshow="myimgesupisshow"
      :filelist="filelist"
      v-on:getuploadfile="getUploadFileEvent"
      v-on:deluploadfile="delUploadFileEvent"
    ></mainimgesup>
  </div>
  <div style="margin-top: 40px">
    <!-- [按钮]上传图片&文件-[前端->ali-oss] -->
    <mainimgesup
      v-model="ruleForm1.sourcefile"
      :myimgesup="myimgesup1"
      :myimgesupisshow="myimgesupisshow1"
      :filelist="filelist1"
      v-on:getuploadfile="getUploadFileEvent"
      v-on:deluploadfile="delUploadFileEvent"
    ></mainimgesup>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import mainimgesup from "@/components/Mainimgesup.vue";

export default defineComponent({
  name: "",
  data() {
    return {
      // -----------------------------------[按钮]上传图片&文件-[前端->后端]-------------------------------------
      ruleForm: {
        sourcefile: "",
      },
      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        showfilelist: true, //list展示列表是否显示，显示true，隐藏false
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/openFile", //upload上传API地址
        headers: { Authen: localStorage.getItem("token") }, //upload上传headers
        multiple: false, //上传多个文件
        title: "点击上传", //button-按钮文字
        title_type: "primary", //button-按钮显示样式,primary&text
        title_plain: true, //button-按钮是否为线性（plain）
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        tips: "建议尺寸46px*46px", //提示&建议文字
        limit: "10", //上传数量限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度，0表示不限宽
            image_height: 0, //图片高度，0表示不限高
            size: 10000, //文件大小（单位kb）
            type: [
              "image/jpeg",
              "image/jpg",
              "image/gif",
              "image/bmp",
              "image/png",
            ], //文件格式
            suffix: ['jpeg', 'jpg','gif','bmp','png'], //文件后缀-用于判断
            typechart: "png/bmp/gif/jpg/jpeg", //文件格式提示规则
          },
          files: {
            size: 10000, //文件大小（单位kb）
            type: ["text/plain", "application/pdf", "application/msword"], //文件格式
            typechart: "text/pdf/word", //文件格式提示规则
          },
          video: {
            size: 10000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
          audio: {
            size: 10000, //文件大小（单位kb）
            type: ["audio/mpeg"], //文件格式
            typechart: "mp3", //文件格式提示规则
          },
        },
      },
      filelist: [
        {
          name: "food.jpeg",
          url: "https://js-share-currency.oss-cn-beijing.aliyuncs.com/images/qiaoba.jpg",
        },
        {
          name: "ceshi",
          url: "https://js-share-currency.oss-cn-beijing.aliyuncs.com/images/690X440.jpg",
        },
      ],

      // ---------------------------------[按钮]上传图片&文件-[前端->ali-oss]------------------------------------
      /**
       * target_id 详解：
       * 1-二维码； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件；8-常见问题图标
       * 9-退款；10-退款审核；11-提现发票；50-设备广告； 100-通用；101设置；
       * 
       */
      ruleForm1: {
        sourcefile: "",
      },
      myimgesupisshow1: true, //form表单-imgesuploade上传图片
      myimgesup1: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        showfilelist: true, //list展示列表是否显示，显示true，隐藏false
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/openFile", //将alioss上传成功的URI地址上传给后端API接口存储
        headers: { Authen: localStorage.getItem("token") }, //后端API接口的headers
        method: "post", //后端API接口请求方式，get|post
        multiple: false, //上传多个文件，允许true，不允许false
        title: "点击上传", //button-按钮文字
        title_type: "primary", //button-按钮显示样式,primary&text
        title_plain: true, //button-按钮是否为线性（plain）
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        tips: "建议尺寸46px*46px", //提示&建议文字
        limit: "10", //上传数量限制，为空表示不限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；
        rules: {
          images: {
            image_width: 0, //图片宽度，0表示不限宽
            image_height: 0, //图片高度，0表示不限高
            size: 10000, //文件大小（单位kb）
            type: [
              "image/jpeg",
              "image/jpg",
              "image/gif",
              "image/bmp",
              "image/png",
            ], //文件格式
            suffix: ['jpeg', 'jpg','gif','bmp','png'], //文件后缀-用于判断
            typechart: "png/bmp/gif/jpg/jpeg", //文件格式提示规则
          },
          files: {
            size: 10000, //文件大小（单位kb）
            type: ["text/plain", "application/pdf", "application/msword"], //文件格式
            typechart: "text/pdf/word", //文件格式提示规则
          },
          video: {
            size: 10000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
          audio: {
            size: 10000, //文件大小（单位kb）
            type: ["audio/mpeg"], //文件格式
            typechart: "mp3", //文件格式提示规则
          },
        },
      },
      filelist1: [
        {
          name: "food1.jpeg",
          url: "https://js-share-currency.oss-cn-beijing.aliyuncs.com/images/qiaoba.jpg",
        },
        {
          name: "ceshi1",
          url: "https://js-share-currency.oss-cn-beijing.aliyuncs.com/images/690X440.jpg",
        },
      ],
    };
  },
  methods: {
    //获取upload子组件回传的数据
    getUploadFileEvent(data) {
      console.log("当前获取的回传数据：", data);
      this.ruleForm.sourcefile = data.url;
      console.log(this.ruleForm.sourcefile);
    },
    //获取upload子组件回传-删除后的filelist数据
    delUploadFileEvent(filelist) {
      console.log("当前获取的回传的filelist：", filelist);
    },
  },
  components: {
    mainimgesup,
  },
});
</script>